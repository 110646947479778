export const newLayer = () => ({
  type: 'NEW_LAYER'
})

export const editLayer = (layer) => ({
  type: 'SELECT_LAYER',
  layer
});

export const deleteLayer = (layerId) => ({
  type: 'DELETE_LAYER',
  layerId
});

export const duplicateLayer = (layer) => ({
  type: 'DUPLICATE_LAYER',
  layer
})

export const deleteSuccess = (layerId) => ({
  type: 'LAYER_DELETE_SUCCESS',
  layerId
})

export const deleteError = (errors, layerId) => ({
  type: 'LAYER_DELETE_FAILURE',
  layerId
})

export const moveLayer = (initialPosition, endPosition) => ({
  type: 'MOVE_LAYER',
  initialPosition,
  endPosition
})


export const layerMoveFailure = (initialPosition, endPosition) => ({
  type: 'LAYER_MOVE_FAILURE',
  initialPosition: endPosition,
  endPosition: initialPosition
})
