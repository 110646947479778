import { deleteSuccess, deleteError } from 'actions/LayersManagement';
import { deleteLayer } from 'services/api/layer_requests';

export const deleteLayerMiddleWare = ({ dispatch, getState }) => next => action => {
  if(action.type !== "DELETE_LAYER") {
    return next(action)
  }

  const { layerId } = action;

  deleteLayer(layerId)
    .then(res => dispatch(deleteSuccess(layerId)))
    .catch(err => dispatch(deleteError(err, layerId)));
};
